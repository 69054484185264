<template>
  <div class="flex items-center justify-center leading-none py-4">
    <span
      class="typcn typcn-arrow-left text-3xl hover:text-green cursor-pointer"
      @click="initWeek(-1)"
    ></span>
    <h3 class="mx-3">{{ startOfWeek.format("W") }}. nädal</h3>
    <span
      class="typcn typcn-arrow-right text-3xl hover:text-green cursor-pointer"
      @click="initWeek(1)"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    startOfWeek: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    initWeek(change) {
      this.$emit("initWeek", change);
    }
  }
};
</script>
