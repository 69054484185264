<template>
  <div
    class="shadow daily-task flex rounded-xl cursor-pointer hover:bg-opacity-15 mb-1 absolute z-10 bg-opacity-5 duration-100"
    data-no-dragscroll
    :class="
      [5, 6].includes(data.status)
        ? colors.find(x => x.status === data.status).backgroundColor
        : colors.find(x => x.status === data.status).color
    "
    :style="styleObj"
    v-if="displayInited"
    @click="viewTask()"
  >
    <div
      :class="colors.find(x => x.status === data.status).color"
      class="project-color"
    ></div>
    <div class="flex items-center w-full">
      <div class="flex w-full items-center justify-between">
        <div class="flex items-center ml-2">
          <span class="truncate">{{ titleFormatted }}</span>
        </div>
        <div class="flex text-sm mr-2">
          <span>{{
            data.all_day
              ? "Kogu päev"
              : `${startTimeFormatted} - ${endTimeFormatted}`
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import StatusParser from "@/assets/mixins/StatusParser";

const backgroundColours = [
  {
    status: 1,
    color: "bg-lightgrey"
  },
  {
    status: 2,
    color: "bg-pending"
  },
  {
    status: 3,
    color: "bg-attention"
  },
  {
    status: 4,
    color: "bg-danger"
  },
  {
    status: 5,
    color: "bg-turquoise",
    backgroundColor: "bg-turquoise"
  },
  {
    status: 6,
    color: "bg-darkgreen",
    backgroundColor: "bg-darkgrey"
  }
];

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mixins: [StatusParser],
  data() {
    return {
      moment: moment,
      colStart: "",
      colSpan: "",
      displayInited: false,
      styleObj: {
        top: 0,
        height: "0px",
        width: "96%",
        left: "2%",
        right: "2%"
      },
      colors: null
    };
  },
  mounted() {
    this.initDisplayAttrs();
    this.colors = backgroundColours;
  },
  methods: {
    initDisplayAttrs() {
      if (this.data.all_day) {
        this.styleObj.height = 24 * 30 + "px";
      } else {
        this.styleObj.top = moment(this.data.start_time).hour() * 30 + "px";
        this.styleObj.height =
          moment
            .duration(
              moment(this.data.end_time).diff(moment(this.data.start_time))
            )
            .asHours() *
            30 +
          "px";
      }

      this.displayInited = true;
    },
    viewTask() {
      this.$store.dispatch("taskViewer/openTaskModal", { task: this.data.id });
    }
  },
  computed: {
    color() {
      return this.parseTaskStatusColor(this.data.status);
    },
    startTimeFormatted() {
      return moment(this.data.start_time).format("HH:mm");
    },
    endTimeFormatted() {
      return moment(this.data.end_time).format("HH:mm");
    },
    titleFormatted() {
      const taskLength = moment
        .duration(moment(this.data.end_time).diff(moment(this.data.start_time)))
        .asHours();
      if (this.data.title.length > 9 * taskLength) {
        const wordArr = this.data.title.split(" ");
        if (wordArr.length > 9 * taskLength) {
          let strToReturn = "";
          for (let i = 0; i < wordArr.length; i++) {
            if (strToReturn.length > 9 * taskLength) {
              strToReturn += "...";
              return strToReturn;
            } else {
              if (wordArr[i].length < 9 * taskLength) {
                strToReturn += wordArr[i] + " ";
              } else {
                strToReturn += wordArr[i].slice(0, 9 * taskLength) + "...";
              }
            }
          }
          return strToReturn;
        } else {
          return this.data.title.slice(0, 9) + "...";
        }
      } else {
        return this.data.title;
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.initDisplayAttrs();
      }
    }
  }
};
</script>
