<template>
  <div class="flex flex-col w-full">
    <div
      class="flex bg-white w-full p-3 items-center cursor-pointer hover:bg-offwhite hover:bg-opacity-50"
      @click="viewTask()"
    >
      <div class="w-auto font-semibold text-lg">{{ data.title }}</div>
      <div class="w-auto flex justify-start flex-grow pl-4">
        <span
          >{{
            data.all_day
              ? "Kogu päev "
              : `${moment(data.start_time).format("HH:mm")} - ${moment(
                  data.end_time
                ).format("HH:mm")}`
          }}
        </span>
      </div>
      <div class="w-6/12 flex justify-end">
        <div
          class="rounded-full bg-white h-10 w-10 items-center justify-center flex font-semibold shadow ml-1"
          v-for="item in data.workers"
          :key="item.id"
        >
          <span>{{ item.profile_name[0] }}</span>
        </div>
      </div>
    </div>
    <div class="w-full h-1 rounded-full bg-offwhite"></div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      moment: moment
    };
  },
  methods: {
    viewTask() {
      this.$store.dispatch("taskViewer/openTaskModal", { task: this.data.id });
    },
    getProfileInitials(name) {
      let string;
      let splitName = name.split(" ");

      for (let i = 0; i < splitName.length; i++) {
        if (i === 0) {
          string = splitName[i][0];
        } else {
          string = " " + splitName[i][0];
        }
      }

      return string;
    }
  }
};
</script>
