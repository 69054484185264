<template>
  <div
    class="flex rounded-xl font-normal mb-1 text-sm flex-wrap hover:shadow justify-between break-words cursor-pointer shadow"
    @click="viewTask"
    @click.stop
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      class="rounded-l-xl project-color"
      :class="parseTaskStatusColor(task.status)"
    ></div>
    <div
      class="flex flex-col flex-auto rounded-r-xl py-1 px-2 justify-between items-start bg-opacity-5 duration-100"
      :class="[
        isHovered ? 'bg-darkgreen text-white bg-opacity-75' : '',
        task.status === 5
          ? 'bg-turquoise hover:bg-turquoisedark'
          : parseTaskStatusColor(task.status)
      ]"
    >
      <span class="font-medium">{{ titleFormatted }}</span>
      <div class="flex">
        <span class="text-sm">{{
          task.all_day
            ? "Kogu päev"
            : `${startTimeFormatted} - ${endTimeFormatted}`
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import StatusParser from "@/assets/mixins/StatusParser";
export default {
  props: {
    task: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      isHovered: false
    };
  },
  mixins: [StatusParser],
  computed: {
    startTimeFormatted() {
      return moment(this.task.start_time).format("HH:mm");
    },
    endTimeFormatted() {
      return moment(this.task.end_time).format("HH:mm");
    },
    titleFormatted() {
      if (window.innerWidth < 1150) {
        if (this.task.title.length > 7) {
          const wordArr = this.task.title.split(" ");
          if (wordArr.length > 7) {
            let strToReturn = "";
            for (let i = 0; i < wordArr.length; i++) {
              if (strToReturn.length > 7) {
                strToReturn += "...";
                return strToReturn;
              } else {
                if (wordArr[i].length < 7) {
                  strToReturn += wordArr[i] + " ";
                } else {
                  strToReturn += wordArr[i].slice(0, 7) + "...";
                }
              }
            }
            return strToReturn;
          } else {
            return this.task.title.slice(0, 7) + "...";
          }
        } else {
          return this.task.title;
        }
      } else {
        if (this.task.title.length > 9) {
          const wordArr = this.task.title.split(" ");
          if (wordArr.length > 9) {
            let strToReturn = "";
            for (let i = 0; i < wordArr.length; i++) {
              if (strToReturn.length > 9) {
                strToReturn += "...";
                return strToReturn;
              } else {
                if (wordArr[i].length < 9) {
                  strToReturn += wordArr[i] + " ";
                } else {
                  strToReturn += wordArr[i].slice(0, 9) + "...";
                }
              }
            }
            return strToReturn;
          } else {
            return this.task.title.slice(0, 9) + "...";
          }
        } else {
          return this.task.title;
        }
      }
    }
  },
  methods: {
    viewTask() {
      this.$store.dispatch("taskViewer/openTaskModal", { task: this.task.id });
    }
  }
};
</script>
